import * as React from "react";

import { FormSpec, localized } from "../../../form-specs";
import { LocalizedContentBase, Site, WithId } from "@maxxton/cms-api";
import { PageWidgetSpec, Widget } from "../../";
import { getLocalizedContent, setMenuLinks } from "../../../utils/localizedContent.util";

import { Breadcrumb } from "./Breadcrumb";
import { LocalizedFriendlyUrl } from "../../sitemap/sitemap.types";
import { SmartLink } from "../../../components/SmartLink";
import { UrlParamsUtil } from "../../../utils/urlparam.util";
import { WidgetGroup } from "../../widget.enum";
import { allSites } from "../../../components/utils";
import { getCurrentPageWidget } from "../../../utils/site.util";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface Link {
    label: string;
    value: string;
}

export interface BreadcrumbOptions {
    links: Link[];
    selectedMenu: Record<string, unknown>;
    labels: string;
    useLabels: boolean;
}

export interface LocalizedBreadcrumb extends LocalizedContentBase {
    breadcrumbOptions: BreadcrumbOptions;
    locale: string;
}

export interface WidgetOptions {
    localizedBreadcrumbOptions: LocalizedBreadcrumb[];
    diplayBreadcrumb: string;
}

interface BreadcrumbLink {
    value: string;
    label: string;
    siteId: string;
    useAsBreadcrumb: boolean;
}

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "breadcrumb-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetBreadcrumb, "breadcrumbWidget"),
    pluralName: getI18nLocaleObject(namespaceList.widgetBreadcrumb, "breadcrumbWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            localized({
                                variable: "localizedBreadcrumbOptions",
                                tabContent: [
                                    {
                                        variable: "breadcrumbOptions",
                                        type: "breadcrumb",
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [
                        [
                            {
                                variable: "diplayBreadcrumb",
                                label: getI18nLocaleObject(namespaceList.widgetBreadcrumb, "displayBreadcrumbOptions"),
                                type: "select",
                                optionList: [
                                    { value: "showOnlyIcon", label: getI18nLocaleObject(namespaceList.widgetBreadcrumb, "showOnlyIcon") },
                                    { value: "showOnlyLabel", label: getI18nLocaleObject(namespaceList.widgetBreadcrumb, "showOnlyLabel") },
                                    { value: "showIconLabel", label: getI18nLocaleObject(namespaceList.widgetBreadcrumb, "showIconLabel") },
                                ],
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

export const breadcrumbWidget: PageWidgetSpec<WidgetOptions> = {
    id: "breadcrumb",
    type: "page",
    widgetGroup: WidgetGroup.CONTENT,
    name: getI18nLocaleObject(namespaceList.widgetBreadcrumb, "breadcrumbWidget"),
    description: getI18nLocaleObject(namespaceList.widgetBreadcrumb, "breadcrumbWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        localizedBreadcrumbOptions: [],
        diplayBreadcrumb: "showOnlyIcon",
    }),

    // eslint-disable-next-line max-lines-per-function
    async render(widget: Widget<WidgetOptions>, context) {
        const { currentLocale, site } = context;
        let sitePage: any = null;
        const sites: Array<Site & WithId> = allSites.length ? allSites : await context.cmsApi.siteApi.find({ projection: { sitemap: 0 } });
        // if condition to get the current page in page preview
        // else condition to get the current page in actual page rendering using friendly url
        if ((context as any).item) {
            sitePage = await context.cmsApi.siteApi.findSitemapByPageAndSite({
                siteId: site._id,
                pageId: (context as any).item._id,
            });
        } else {
            sitePage = await getCurrentPageWidget(context);
        }
        const pages: any[] = [];
        let links: BreadcrumbLink[] | Link[] = [];

        async function setPageOptions() {
            const pageOptions = pages.map(async (page: any) => {
                if (!page.useAsBreadcrumb) {
                    return { value: "", label: "", siteId: "", useAsBreadcrumb: false };
                }

                const fetchedPage: any = await context.cmsApi.pageApi.findById({ id: page.pageId });
                if (!fetchedPage) {
                    return { value: "", label: "", siteId: "", useAsBreadcrumb: false };
                }
                let site: any;
                if (sites?.length) {
                    site = sites.find((item) => item._id === page.siteId);
                }
                const sitePage1 = await context.cmsApi.siteApi.findSitemapByPageAndSite({
                    siteId: context.site._id,
                    pageId: fetchedPage._id,
                });
                const label = page.localized.find((p: any) => p.locale === context.currentLocale.locale);
                const localizedFriendlyUrl: LocalizedFriendlyUrl | null = getLocalizedContent({ site, currentLocale, localizedContent: sitePage1?.options.localizedFriendlyUrl });
                const friendlyUrl = localizedFriendlyUrl?.friendlyUrl || sitePage1?.options.friendlyUrl;
                return {
                    siteId: page.siteId,
                    value: `${await UrlParamsUtil.correctTrailingSlash(friendlyUrl, site)}`,
                    label: label ? label.label : "" || fetchedPage.name,
                    useAsBreadcrumb: page.useAsBreadcrumb,
                };
            });

            const res = await Promise.all(pageOptions);
            if (res.length) {
                links = res.filter((page) => page.useAsBreadcrumb);
            }
        }
        // eslint-disable-next-line max-len
        const localizedBreadcrumb: LocalizedBreadcrumb | null = getLocalizedContent({ site, currentLocale, localizedContent: widget.options.localizedBreadcrumbOptions });
        if (!localizedBreadcrumb || !localizedBreadcrumb.breadcrumbOptions) {
            return <div />;
        }
        const { useLabels, labels, selectedMenu } = localizedBreadcrumb.breadcrumbOptions;
        links = localizedBreadcrumb.breadcrumbOptions.links;
        const menu = await context.cmsApi.menuApi.findById({ id: (selectedMenu as any).value });
        if (selectedMenu && menu) {
            setMenuLinks(menu, sitePage?.options?.pageId, pages, currentLocale?.locale);
            await setPageOptions();
        }
        let customLabels: string[] = [];
        if (useLabels) {
            customLabels = labels ? labels.split(",") : [];
        }
        const getLinks: Array<JSX.Element | null> = await Promise.all(
            links.map(
                async (link: Link, index: number): Promise<JSX.Element | null> => {
                    if (!link.value && !link.label) {
                        return null;
                    }

                    if (index === links.length - 1) {
                        return (
                            <span className="breadcrumb-hierarchy__text" key={`breadcrumb-link-${index}`}>
                                {customLabels[index] || link.label}
                            </span>
                        );
                    }

                    const localeCode = site.enableMultiLanguage && context.currentLocale.locale !== site.locale._id ? `/${context.currentLocale.code}` : "";
                    const linkConfigSite: Site | undefined = sites.find((siteItem) => siteItem._id === (link as any).siteId);
                    return (
                        <SmartLink
                            key={index}
                            href={`//${linkConfigSite ? linkConfigSite.host : context.site.host}${link.value.startsWith(localeCode) ? "" : localeCode}${link.value}`}
                            className="breadcrumb-hierarchy__link"
                        >
                            {customLabels[index] || link.label}
                        </SmartLink>
                    );
                }
            )
        );
        // We populate the fresh breadcrumb menu links(using setMenuLinks,setPageOptions and updatePages)
        // instead of using the  breadcrumb options
        // which are coming through widget.options.breadcrumbOptions (populated at the time
        // of breadcrumb widget configuration at page hierarchy). Its necessary to get fresh menu links because
        // there might be the cases where breadcrumb widget is already configured in page but
        // later on some one change the site/page spec at Menu -> breadcrumb options
        // so it will not populate the fresh links in existing breadcrumb widget
        // until someone edit,re-select the old menu and save it in widget options.
        const linksHtml: any = getLinks;
        return <Breadcrumb options={widget.options} linksHtml={linksHtml} context={context} />;
    },
};
