import * as FontAwesome from "react-fontawesome";
import * as React from "react";

import { LocalizedBreadcrumb, WidgetOptions } from "./";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { SmartLink } from "../../../components/SmartLink";
import { getI18nLocaleString } from "../../../i18n";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import namespaceList from "../../../i18n/namespaceList";

interface BreadcrumbProps extends PageWidgetBaseProps<WidgetOptions> {
    linksHtml: any;
    context: CMSProvidedProperties;
}

interface BreadcrumbState {
    host: string;
    site: string;
    linksHtml: string;
    pathList: string[];
}

export class Breadcrumb extends React.PureComponent<BreadcrumbProps, BreadcrumbState> {
    constructor(props: BreadcrumbProps) {
        super(props);
        this.state = {
            linksHtml: "",
            site: "",
            host: "",
            pathList: [],
        };
    }

    public componentDidMount() {
        const url = window.location.href.replace(/\?/g, "");
        const urlPathList = url.split(window.location.protocol + "//")[1].split("/");
        this.setState({ host: urlPathList[0], pathList: urlPathList });
    }

    public render(): JSX.Element | null {
        const {
            context: { currentLocale, site },
            linksHtml,
            options,
        } = this.props;
        const { pathList } = this.state;
        let breadcrumbListItem = "";
        let path = "";
        const { host } = this.state;
        const { localizedBreadcrumbOptions } = this.props.options;
        if (this.props.linksHtml.length > 0) {
            this.props.linksHtml.forEach((item: any, index: any) => {
                path += `/${pathList[index + 1]}`;
                // At first render, host and path are empty and undefined, hence checking
                if (path !== "/undefined" && host) {
                    // eslint-disable-next-line max-len
                    breadcrumbListItem += `{"@type": "ListItem", "position": ${index + 1}, "name": "${item.props.children}", "item": "https://${host}${path}"}${
                        index !== this.props.linksHtml.length - 1 ? "," : ""
                    }`;
                }
            });
        }
        // eslint-disable-next-line max-len
        const localizedBreadcrumb: LocalizedBreadcrumb | null = getLocalizedContent({ site, currentLocale, localizedContent: localizedBreadcrumbOptions });
        if (!localizedBreadcrumb || !localizedBreadcrumb.breadcrumbOptions) {
            return <div />;
        }
        if (localizedBreadcrumb.breadcrumbOptions!.links.length <= 0) {
            return <div />;
        }

        // eslint-disable-next-line max-len
        const schemaObject = `{"@context": "http://schema.org", "@type": "BreadcrumbList", "itemListElement": [${breadcrumbListItem}]}`;
        const schemaCode = breadcrumbListItem ? schemaObject : null;

        return (
            <div className="breadcrumb-hierarchy">
                <SmartLink href={"/"} className="breadcrumb-hierarchy__link">
                    {options.diplayBreadcrumb === "showOnlyIcon" ? (
                        <FontAwesome name="home" size="lg" />
                    ) : options.diplayBreadcrumb === "showOnlyLabel" ? (
                        getI18nLocaleString(namespaceList.widgetBreadcrumb, "home", currentLocale, site)
                    ) : options.diplayBreadcrumb === "showIconLabel" ? (
                        <span>
                            <FontAwesome name="home" size="lg" {...{ className: "mr-1" }} />
                            {getI18nLocaleString(namespaceList.widgetBreadcrumb, "home", currentLocale, site)}
                        </span>
                    ) : (
                        <FontAwesome name="home" size="lg" />
                    )}
                </SmartLink>
                {linksHtml}
                {schemaCode && (
                    <div className="breadcrumbs-schema">
                        <script type="application/ld+json">{schemaCode}</script>
                    </div>
                )}
            </div>
        );
    }
}
